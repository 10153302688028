export const translations = {
  pickerWithInput: {
    date: {
      'en-150': 'Date',
      'sk-SK': 'Dátum'
    },
    time: {
      'en-150': 'Time',
      'sk-SK': 'Čas'
    }
  }
}
